import React, { useState, useEffect } from 'react'
import { Button, Box } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import TextsmsIcon from '@mui/icons-material/Textsms';
import './selfieOrChat.css'
import { uploadPicture, processAnalysis, skinType } from '../../api/service.js'
import { saveResWidget } from '../../utils/utils';
import { getRequest, sortScoreArray } from '../../utils/helper';
import Pub from '../../components/pub/pub';
import { Langage } from "../../language";
import { appLanguage, titleNormalized } from "../../select";
import { getRoutine } from '../../api/api-conseil-hair';
import CircularProgress from '@mui/material/CircularProgress';


const SelfieOrChat = (props) => {
    const { setState } = props

    const [takePic1, setTakePic1] = useState(false); // verifie si la photo 1 a etet rpis dans cactus hair
    const [takePic2, setTakePic2] = useState(false); // verifie si la photo 1 a etet rpis dans cactus hair
    const [openPub, setOpenPub] = useState(false);
    const [showFullText, setShowFullText] = useState(false);
    const [routine, setRoutine] = useState(null);
    const [afterLoading, setAfterLoading] = useState(false);

    const toggleText = () => {
        setShowFullText(!showFullText);
        props.actionProvider.next(5)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const request = getRequest(props.state);
                const routine = await getRoutine(request);
                setState(prevState => ({
                    ...prevState,
                    recommendationGPT: routine
                  }))
                //const routineFormatted = routine.replace(/\n/g, "<br>");
                setRoutine(routine);
            } catch (error) {
                // Gérez les erreurs ici
                console.error(error);
            }
        };

        fetchData(); // Appelez la fonction asynchrone ici
    }, []);

    /*  useEffect(() => {
         if (routine && takePic1 && takePic2)
             props.actionProvider.next(5)
     }, [routine, takePic1, takePic2 ]); */
    useEffect(() => {
        if (routine && takePic1 && takePic2)
            setTimeout(() => {
                setAfterLoading(true);
            }, 3000);
    }, [routine, takePic1, takePic2]);

    // recoit les scores et les formattes de la bonne facon pour les envoyer a l api
    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    const extractScore = (arr) => {
        let scores = {}
        Object.keys(arr).forEach((key) => scores[key] = arr[key].final)
        return scores
    }

    const handleCapture = async (target) => {

        if (target.files && titleNormalized === 'chatbot-hair') {
            if (target.files.length !== 0) {
                const file = target.files[0];
                // const newUrl = URL.createObjectURL(file);
                // setSource(newUrl);
                const tmp = { "imgSelfie": [...props.state.imgSelfie, file] }
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
            }
            /* if (takePic1)
                props.actionProvider.next(5); */

            if (takePic1)
                setTakePic2(true)
            setTakePic1(true)
            return;
        }

        else if (target.files && titleNormalized !== 'chatbot-hair') {
            if (target.files.length !== 0) {
                const file = target.files[0];
                // const newUrl = URL.createObjectURL(file);
                // setSource(newUrl);
                const tmp = { "imgSelfie": file }
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
                await postPicture(file)
            }
        }
        // faire un post de la photo pour aue l api puisse rendre le type de peau en question et le stocker dans le state typeSkin
    }

    const postPicture = async (image) => {
        setOpenPub(true);
        try {
            let result
            result = await uploadPicture(image)
            if (result.success) {
                const uid = result.uid
                const analysis = await processAnalysis(uid)
                const scores = extractScore(analysis.scores)
                const res = await skinType(scores, 50);
                const tmp = {
                    uid: uid,
                    analysis: analysis,
                    needs: sortScoreArray(analysis.scores),
                    myNeeds: sortScoreArray(analysis.scores).slice(-3),
                    skinType: res.skintype,
                    arg: res.arguments,
                    open: openPub
                }
                setOpenPub(false);
                props.actionProvider.next(5);
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
            }
        } catch (err) {
            setOpenPub(false);
            console.error(err);
        }
    };

    return (<div className="btns">
        <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
            {titleNormalized === 'chatbot-hair' ? getText('take_selfie_hair') : getText('take_selfie')}
            <input hidden
                accept="image/*"
                type="file"
                capture="environment"
                onChange={(e) => {
                    saveResWidget('Prendre mon selfie', setState); setState((prev) => {
                        return { ...prev, ...{ selfie: true } }
                    }); handleCapture(e.target)
                }} />
        </Button>
        {/* demande une deuxime photo du crane si on est dans cactus hair */}
        {takePic1 && <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
            {getText('take_selfie_scalp')}
            <input hidden
                accept="image/*"
                type="file"
                capture="environment"
                onChange={(e) => {
                    saveResWidget('Prendre mon selfie', setState); setState((prev) => {
                        return { ...prev, ...{ selfie: true } }
                    }); handleCapture(e.target)
                }} />
        </Button>}
        {routine && afterLoading &&
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                whiteSpace: 'pre-wrap'
            }}
        >
            <div>
                <span className="react-chatbot-kit-chat-bot-message">
                    {(showFullText ) ? routine : routine.slice(0, 200)}
                    {!showFullText && (
                        <button onClick={toggleText} className="voir-plus-btn">
                            Voir plus
                        </button>
                    )}
                </span>
            </div>
        </Box>}
        {((takePic2 && routine && !afterLoading) || (takePic2 && !routine)) &&
            <div className="react-chatbot-kit-chat-bot-message">
                <span>{getText('routin')}</span>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="primary" size={30} thickness={5} />
                </div>
            </div>}
        {/* {takePic2 && routine && !afterLoading &&
            <div className="react-chatbot-kit-chat-bot-message">
                <span>Votre routine personalisé est en cours de traitement</span>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="primary" size={30} thickness={5} />
                </div>
            </div>} */}
        {/*<Button onClick={ () => {saveResWidget('Démarrer le chat', setState); setState((prev) => {*/}
        {/*            return { ...prev, ...{selfie: false} } */}
        {/*       });props.actionProvider.next(5) }} variant="contained" startIcon={<TextsmsIcon />}>*/}
        {/*    Démarrer le chat*/}
        {/*</Button>*/}
        {/*{source &&*/}
        {/*    <Box display="flex" justifyContent="center">*/}
        {/*      <img height="250" src={source} alt={"snap"}></img>*/}
        {/*    </Box>}*/}
        <Pub open={openPub}>
        </Pub>
    </div>)
}

export default SelfieOrChat

import { appLanguage, titleNormalized } from "./select";
import { LanguageCoiffeur } from "./language";

const getText = (key) => {
    return LanguageCoiffeur[key][appLanguage]
}


export const coiffeurQt = [
    {
        "uid": 0,
        "question": getText('generate_CA'),
        "min_len": 0,
        "delay": 6000,
        "next": 1,
        "numeric": true
    },
    {
        "uid": 1,
        "question": getText('email'),
        "min_len": 4,
        "next": 2
    },
    {
        "uid": 2,
        "question": getText('salon_hairdresser'),
        "min_len": 4,
        "next": 3,
        "numeric": true
    },
    {
        "uid": 3,
        "question": getText('salon_customers'),
        "min_len": 4,
        "next" : 4,
        "numeric": true
    },
    {
        "uid": 4,
        "question": getText('average purchase'),
        "min_len": 5,
        "next" : 5,
        "numeric": true
    },
    {
        "uid": 5,
        "question": 'A calculer dans Action Provider',
        "min_len": 5,
        "numeric": true
    }
  
]

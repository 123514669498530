import React, { useState, useEffect} from 'react'
import { startingQuestions } from '../startingQuestions.js'
import { needsBot } from '../needsBot.js'
import { error } from '../error.js';
import { createContact } from '../api/service.js';
import { appLanguage, titleNormalized } from "../select";
import {Langage, LangageHair} from "../language";
import Speech from 'speak-tts'
import { getRequest, getRequestForGptHair, replaceDashesAndUnderscores } from '../utils/helper.js';
import { haireQt } from '../hairQt.js';
import { CoiffeurQt, coiffeurQt } from '../coiffeurQt.js';




const speech = new Speech()
if (speech.hasBrowserSupport()) {
  // console.log("speech synthesis supported")
  speech.init({
    'volume': 1,
    'lang': (appLanguage === 'french' ? 'fr-FR' : 'en-US'),
    'rate': 1,
    'pitch': 1,
    'voice': (appLanguage === 'french' ? 'Google français' : ((titleNormalized === 'new-pharma') ? 'Microsoft Mark - English (United States)' : 'Google US English')),
    'splitSentences': true,
    'listeners': {
      'onvoiceschanged': (voices) => {
        // console.log("Event voiceschanged", voices)
      }
    }
  }).then((data) => {
  }).catch(e => {
    console.error("An error occured while initializing : ", e)
  })
}

const getText = (key) => {
  return Langage[key][appLanguage];
}

const handleVideo = (src, text, time) => {
  let video = document.getElementById('vid');
  let video_text_span = document.getElementById('vid_text_span');
  let container_video = document.getElementById('container_vid')

  video.src = src
  video_text_span.style.animation = 'none';
  void video_text_span.offsetWidth; /* trigger reflow */
  video_text_span.style.animation = null;
  video_text_span.textContent = text
  container_video.style.transform = 'translateY(0)';
  video.play();
  setTimeout(() => {
    container_video.style.transform = 'translateY(1000%)';
  }, time)
}
const handleTTS = (message, delay = 500) => {
  if (!localStorage.getItem('MUTE_TTS') || !JSON.parse(localStorage.getItem('MUTE_TTS'))) {
    setTimeout(() => {
      let msg
      message.payload.choices ? msg = message.message + message.payload.choices.map(item => item.text).join(', ') : msg = message.message
      speech.speak({ text: replaceDashesAndUnderscores(msg) });
    }, delay);
  }
}





const ActionProvider = ({ createChatBotMessage, setState, children }) => {

 

  
  /* useEffect(() => {
    // Sélectionnez vos éléments d'entrée et de bouton ici (remplacez 'inputBar' et 'sendButton' par les références réelles)
  const inputBar = document.querySelector('.react-chatbot-kit-chat-input'); // Remplacez 'votre-input-id' par l'ID de votre élément d'entrée
  const sendButton = document.querySelector('.react-chatbot-kit-chat-btn-send');
  
  
    // Gestionnaire d'événement pour la touche "Enter" et la touche "Flèche bas"
     inputBar.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        //event.preventDefault();
        inputBar.blur();
      }
    }); 
  
    // Gestionnaire d'événement pour le bouton
    sendButton.addEventListener('click', () => {
      inputBar.blur();
    });
  
    // Nettoyage des gestionnaires d'événements lors du démontage du composant
    return () => {
      inputBar.removeEventListener('keydown', () => {});
      sendButton.removeEventListener('click', () => {});
    };
  }, []); */

 

  let app; // todo remove after salon
  const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
  if(pharmaId === '2087398') {
    app = 'jess';
  } else {
    app = titleNormalized;
  }

  //const { actionProvider } = children
  const states = children.props.children.props.state
  const [needsFlag, setNeedsFlag] = useState(false)
  const [hairFlag, setHairFlag] = useState(false)
  const [needQ, setNeedQ] = useState([])
  const [needHairQ, setNeedHaireQ] = useState([])
  //const [res, setRes] = useState()

  // useEffect(() => {
  //   setRes(parseInt(states['0']) / parseInt(states['4']) / 0.2 / 24 / parseInt(states['2']))
  //  }, [res]);

  const getNextId = (id, type) => {
    return getQuestion(id, type).next ? getQuestion(id).next : null
  }

  const getQuestion = (id, type = 'starting') => {

    switch(type){
      case 'starting':
        return coiffeurQt.filter(item => item.uid === id)[0]
      case 'error':
        return error.filter(item => item.uid === id)[0]
    }
    
  }
  
  const getQuestionNeed = (array, id) => {
    return array.filter(item => item.id === id)[0]
  }

  const getQuestionHair = (array, uid) => {
    return array.filter(item => item.uid === uid)[0]
  }

  const getQuestiondHairDresser = (array, uid) => {
    return array.filter(item => item.uid === uid)[0]
  }

  const getNeedsQuestions = (needs) => {
    const keys = [{ key: '_BEFORE' }, ...needs, { key: '_AFTER' }];
    const array = [];
    keys.forEach(element => {
      const besoin = element.key
        .replace("PORES", 'GRAIN DE PEAU')
        .replace("REGULATION", 'BRILLANCE')
        .replace("IMPERFECTIONS", 'BOUTONS');

      const subArr = needsBot[besoin].map(q => ({ besoin, ...q }));
      array.push(subArr);
    });
    return array.flat();
  }

  const next = (id, lastResponse) => {

    let question 
    let type = 'starting'

    if(id < -100)
      type = 'error'

    question = getQuestion(id, type)

    //questions = coiffeurQt
    
    //question = getQuestiondHairDresser(questions, id)
    if(id === 5){
    
    const res =  parseInt(states['0']) / lastResponse / 0.2 / 24 / parseInt(states['2'])
    question.question = ` Félicitations ! 🎉 Vous avez désormais la clé de votre succès en main. Pour atteindre votre objectif de ${states[0]} € de chiffre d'affaires en vente de produit chaque mois, voici la formule magique :
    
    ${Math.round(res)} diagnostics capillaires chaque jour par coiffeur !
    
    C'est tout ce dont vous avez besoin pour booster vos ventes de produits capillaires et fidéliser vos clients.
    
    Rejoignez GYD Beauty aujourd'hui et faites passer votre salon de coiffure au niveau supérieur. Des clients satisfaits et des profits en hausse vous attendent !
    
    🚀 Souscrivez à GYD Beauty dès maintenant !
    Offre spécial à partir de 49 € / mois`
    }
    const input = document.querySelector('.react-chatbot-kit-chat-input');
    if(question.numeric == true)
      input.type = "tel"
    else
      input.type = "text"
    let message = createChatBotMessage(
        question.question,
        {
          payload: { uid: question.uid, next: question.next },
          loading: true,
          terminateLoading: true,
          delay: question.delay || null,
          withAvatar: true,
          widget: question.widget || null
        }
        )


    addMessageToState(message)
  

     if(!message.widget){
      const allDivs = document.querySelectorAll('.react-chatbot-kit-chat-bot-message');
      const lastElement = allDivs[allDivs.length - 1];
      setTimeout(() => {
      lastElement.scrollIntoView()
      }, 1000)
    } 

    
    setTimeout(() => {
      const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
      lastChild.scrollIntoView()
      },question?.delay ? 1500 : 3000)

    const inputBar = document.querySelector('.react-chatbot-kit-chat-input-container');
    if(question?.widget === 'multiChoices' || question?.widget === 'yesOrNot' )
      inputBar.style.display = "none";
    else
      inputBar.style.display = "block";
    
    inputBar.blur();

    if(id === 5){
    const contact = {
      'objectif_ca' : states['0'],
      'objectif_ca_jour' :  parseInt(states['0'])/24,
      'email' : states['1'],
      'nombre_coiffeur' : states['2'],
      'listId' : 10  // a rajouter pour les listes de contact
    }

    createContact(contact)
  }
  };


  const createBotMessage = (msg, options = {}) => {
    const message = createChatBotMessage(
      msg,
      options
    );
    addMessageToState(message)
  };



  // tient a jour le state du bot en ajoutant au state le dernier message recu
  const addMessageToState = (message) => {

    const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
    const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    icon.style.display = 'none';
    btn.append(loading_point);
    setTimeout(() => {
      icon.style.display = 'block';
      loading_point.remove();
    }, 1000);

    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { next, createBotMessage },
        });
      })}
    </div>
  );
};

export default ActionProvider;
